import React from "react";
import styles from "./Header.module.scss";
import Navbar from "../Navbar/Navbar";
import images from "../../static/images";

const Header = () => {
  return (
    <header className={styles.header}>
      <Navbar logo={images.svg.logo} />
      <div className={styles.header__title}>
        <h1>Arrochar</h1>
        <p>Explore the hidden gem at the top of the Clyde.</p>
      </div>
    </header>
  );
};

export default Header;
