const images = {
  photos: {
    imgHeaderBackground: "/assets/images/img-header-background",
    imgLakeside: "/assets/images/img_lakeside",
    imgJetty: "/assets/images/img_jetty",
    imgWomanRunning: "/assets/images/img_woman_running",
  },
  svg: {
    logo: "/assets/images/svg/logo-lochlong",
    imgFooterMobile: "/assets/images/svg/footer-mobile",
    imgFooterDesktop: "/assets/images/svg/footer-desktop",
    waves: "/assets/images/svg/waves",
    waves2: "/assets/images/svg/waves2",
    waves3: "/assets/images/svg/waves3",
    iconCall: "/assets/images/svg/icon-call",
    iconEmail: "/assets/images/svg/icon-email",
    iconFacebook: "/assets/images/svg/icon-facebook",
  },
};
export default images;
