import React from "react";

const Image = ({ src, width, height, alt, title, type = "png", className }) => {
  const basename = process.env.REACT_APP_BASENAME
    ? process.env.REACT_APP_BASENAME.trimEnd()
    : "";

  const renderImage = () => {
    if (type === "svg") {
      return (
        <img
          src={basename + src + "." + type}
          width={width}
          height={height}
          alt={alt}
          title={title}
          className={className}
        />
      );
    } else {
      return (
        <picture>
          <source srcSet={basename + src + ".webp"} type="image/webp" />
          <source srcSet={basename + src + "." + type} type={"image/" + type} />
          <img
            src={basename + src + "." + type}
            width={width}
            height={height}
            alt={alt}
            title={title}
            className={className}
          />
        </picture>
      );
    }
  };

  return <div>{renderImage()}</div>;
};

export default Image;
