import React from 'react';
import styles from './Home.module.scss';
import images from '../../static/images';
import { TbChevronRight } from 'react-icons/tb';
import { IconContext } from 'react-icons';
import Image from '../../components/Image/Image';
import { contact } from '../../data/contact';

const Home = () => {
  return (
    <>
      <div className={styles.wrapper}>
        <section className={`${styles.content} ${styles.contentFirst}`}>
          <div className={styles.content__textWrapper}>
            <h2>Our village</h2>
            <p>
              The Loch Long Jetty sits at the northern end of Loch Long, in the
              village of Arrochar. Crested by the Arrochar Alps and famed for
              its stunning scenery, abundant wildlife and protected waters the
              Jetty and visitor moorings make Arrochar an idyllic and accessible
              cruising location.
            </p>
            <p>
              Our mission was to install a Jetty and moorings to open up the
              village to visitors by boat whilst also allowing visitors by land
              and the local community access to the Sea. Enabling everyone to
              benefit from the beauty of the village, its amazing surroundings,
              walks, wildlife, restaurants, cafes and shops.
            </p>
          </div>
          <Image
            src={images.photos.imgLakeside}
            type={'jpg'}
            alt={'lakeside view'}
          />
        </section>
        <section className={`${styles.content} ${styles.contentMiddle}`}>
          <div className={styles.content__textWrapper}>
            <h2>Funding</h2>
            <p>
              As well as money raised by the use of the Jetty by providing
              visitor moorings to passing pleasure boats, Jetty services and
              grants, we also raise money by running charity events such as
              <a href='#10k'> The Arrochar 10K</a>. The Loch Long Jetty
              Association (LLJA) is an entirely voluntary run organisation and a
              registered charity in Scotland (SC047932). All monies raised by
              the LLJA are reinvested back into the community.
            </p>
            <p>
              For information on our services and charges, please click the
              button below to download our PDF.
            </p>
            <a
              href={contact['jetty-info-pdf']}
              download={'jetty-information'}
              target='_blank'
              rel='noreferrer'
            >
              <button className='cta'>
                Download{' '}
                <IconContext.Provider value={{ size: '23px' }}>
                  <TbChevronRight className='cta__chevron' />
                </IconContext.Provider>
              </button>
            </a>
          </div>
          <Image src={images.photos.imgJetty} alt={'jetty'} type={'jpg'} />
        </section>
      </div>
      <div className={styles.waves} id='10k'>
        <div className={styles.ocean}>
          <svg
            class={styles.waves__wave}
            viewBox='0 24 150 28'
            preserveAspectRatio='none'
            shape-rendering='auto'
          >
            <defs>
              <path
                id='gentle-wave'
                d='M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z'
              />
            </defs>
            <g class={styles.waves__parallax}>
              <use href='#gentle-wave' x='48' y='3' fill='#273852' />
              <use href='#gentle-wave' x='48' y='3' fill='#273852' />
              <use href='#gentle-wave' x='48' y='5' fill='#273852' />
              <use href='#gentle-wave' x='48' y='5' fill='#273852' />
              <use href='#gentle-wave' x='48' y='7' fill='#273852' />
              <use href='#gentle-wave' x='48' y='7' fill='#273852' />
            </g>
          </svg>
        </div>

        <div className={styles.wrapper}>
          <section className={`${styles.content} ${styles.contentLast}`}>
            <div className={styles.content__textWrapper}>
              <h2>The Arrochar 10k</h2>
              <p>
                With rewarding views of Loch Long, you will race through the
                forestry road before descending into the forest paths taking you
                through the beautiful woodlands or Ardgarten. Finishing with a
                run alongside Loch Long with spectacular views of both Arrochar
                and Ben Lomond.
              </p>
              <p>
                <b>21st September 2024. Entries are now open.</b>
              </p>
              <a
                href='https://www.entrycentral.com/Arrochar_10k'
                target='_blank'
                rel='noreferrer'
              >
                <button className='cta'>
                  Enter here{' '}
                  <IconContext.Provider value={{ size: '23px' }}>
                    <TbChevronRight className='cta__chevron' />
                  </IconContext.Provider>
                </button>
              </a>
            </div>
            <Image src={images.photos.imgWomanRunning} alt={'woman running'} />
          </section>
        </div>
      </div>
    </>
  );
};

export default Home;
