import React from "react";
import styles from "./Navbar.module.scss";
import Image from "../Image/Image";

const Navbar = ({ logo }) => {
  return (
    <nav className={styles.navbar}>
      <Image
        src={logo}
        type={"svg"}
        className={styles.navbar__logo}
        alt={"logo"}
      />
    </nav>
  );
};

export default Navbar;
