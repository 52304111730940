import React from "react";
import styles from "./Footer.module.scss";
import images from "../../static/images";
import Image from "../../components/Image/Image";
import { contact } from "../../data/contact";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footer__content}>
        <div className={styles.footer__wrapper}>
          <div className={styles.footer__socials}>
            <a
              href="https://www.facebook.com/lochlongjettyassociation"
              target="_blank"
              rel="noreferrer"
              className={styles.footer__social}
            >
              <Image
                src={images.svg.iconFacebook}
                alt={"facebook icon"}
                type={"svg"}
              />
            </a>
            <a href={`tel:${contact.phone}`} className={styles.footer__social}>
              <Image
                src={images.svg.iconCall}
                alt={"phone icon"}
                type={"svg"}
              />
              <p>{contact.phone}</p>
            </a>
            <a
              href={`mailto:${contact.email}`}
              className={styles.footer__social}
            >
              <Image
                src={images.svg.iconEmail}
                alt={"email icon"}
                type={"svg"}
              />
              <p>{contact.email}</p>
            </a>
          </div>
        </div>
        <div className={styles.footer__wrapper}>
          <p>Charity {contact.charity_no}</p>
        </div>
      </div>
      <Image
        src={images.svg.imgFooterMobile}
        alt={"jetty illustration"}
        type={"svg"}
        className={styles.footer__image}
      />
      <Image
        src={images.svg.imgFooterDesktop}
        alt={"jetty illustration"}
        type={"svg"}
        className={styles.footer__imageDesktop}
      />
    </footer>
  );
};

export default Footer;
